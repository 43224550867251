<template>
  <div class="detailBox">
    <navBar />
    <div class="box">
      <div class="safe">
        <div class="header">
          <div
            class="top"
            :style="{ backgroundImage: 'url(' + backgroudImg + ')' }"
          >
            <p class="p1">{{ grade_data.course_name }}</p>
            <p class="p2" v-if="options.length <= 1">
              {{ grade_data.grade_name }}
            </p>
            <el-select
              v-model="gradeID"
              placeholder="请选择"
              @change="gradeChange"
              v-else
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.grade_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <div class="topBottom">
              <div class="topBottomLeft">
                <img src="../../assets/QRcode.png" alt="" />
                <p class="p11">加课码 {{ grade_data.verification_code }}</p>
                <p>已有{{ grade_data.grade_num }}人加入</p>
                <p
                  style="cursor: pointer; margin-left: 10px"
                  @click="integrationFn"
                >
                  我的积分:{{ userInfo.integral_data }}
                </p>
                <p style="margin-left: 10px">
                  {{ userInfo.integral_data_bfb }}
                </p>
              </div>
              <div>
                <el-button
                  round
                  class="zwkc"
                  :disabled="!play_id"
                  @click="toLiveRoomBtn"
                  ><i class="el-icon-video-play"></i>
                  <span>{{
                    play_id ? "正在直播" : "暂无课程"
                  }}</span></el-button
                >
              </div>
            </div>
            <p style="margin-top: 10px" v-if="userInfo.video_till_time">
              账号有效期:{{ userInfo.video_till_time }}
            </p>
          </div>
          <div class="bottom">
            <div class="bottomC" v-if="is_show_video">
              <p v-for="item in list" :key="item.value" @click="activeC(item)">
                <span
                  :class="item.path == activeIndex ? 'isActive' : ''"
                  v-if="item.shows"
                >
                  {{ item.title }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px">
          <router-view
            :gradeID="gradeID"
            :givePath="givePath"
            @getBackImg="getBackImgFn"
            :is_show_video="is_show_video"
          />
        </div>
      </div>
    </div>
    <integrationDialog ref="integrationDialog" />
    <navFooter />
  </div>
</template>
<script>
import navBar from "@/components/navBar/navBar.vue";
import navFooter from "@/components/navFooter/navFooter.vue";
import integrationDialog from "@/components/integrationDialog.vue";
import { fileList } from "@/api/order.js";
export default {
  data() {
    return {
      list: [
        {
          title: "课程学习",
          value: "1",
          shows: true,
          path: "classStudy",
        },
        {
          title: "课程介绍",
          value: "2",
          shows: true,
          path: "classInfo",
        },
        {
          title: "作业",
          value: "3",
          shows: true,
          path: "homeWorkPage",
        },
        {
          title: "试卷",
          value: "4",
          shows: true,
          path: "testAdmin",
        },
        {
          title: "题海泛舟",
          value: "5",
          shows: false,
          path: "questionBank",
        },
        {
          title: "满意度调查",
          value: "6",
          shows: this.isAfterOrOn15th(),
          path: "stars",
        },
      ],
      gradeID: null,
      options: [],
      activeIndex: this.$route.name,
      userInfo: {},
      backgroudImg: "",
      grade_data: {},
      givePath: "",
      isFirst: true,
      is_show_video: 0,
      dialogVisible: false,
      play_id: "",
      is_open_th: 1,
    };
  },
  created() {
    this.getList("", 0, 0);
    let a = JSON.parse(localStorage.getItem("userInfo"));
    this.userInfo = a;

    // if (sessionStorage.getItem("backimg")) {
    //   this.backgroudImg = sessionStorage.getItem("backimg");
    // } else {
    //   this.backgroudImg = require("../../assets/42.png");
    // }
  },
  watch: {
    // gradeID: {
    //   handler(v) {
    //     if (
    //       v == 1431 ||
    //       v == 1432 ||
    //       v == 1433 ||
    //       v == 1435 ||
    //       v == 1434 ||
    //       v == 1436 ||
    //       v == 1437 ||
    //       v == 1438 ||
    //       v == 1439 ||
    //       v == 1441 ||
    //       v == 1442
    //     ) {
    //       this.list[4].shows = true;
    //     } else {
    //       this.list[4].shows = false;
    //     }
    //   },
    //   deep: true,
    // },
  },
  methods: {
    isAfterOrOn15th() {
      const today = new Date();
      const currentDay = today.getDate();
      if (currentDay > 15) {
        return true;
      } else {
        return false;
      }
    },
    integrationFn() {
      this.$refs.integrationDialog.show();
    },
    toLiveRoomBtn() {
      const routeUrl = this.$router.resolve({
        name: "liveRoom",
        query: {
          phone: this.userInfo.number,
          play_id: this.play_id,
          grade_id: this.userInfo.grade_id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    getList(dir, index, type, grade_id) {
      fileList({ dir, is_give: type, grade_id }).then((res) => {
        this.play_id = res.play_id;

        this.backgroudImg =
          res.grade_data.back_image || require("../../assets/42.png");
        this.options = res.grades;
        if (this.isFirst) {
          this.gradeID = res.grades[0].id;
          sessionStorage.setItem("gradeID", this.gradeID);
          this.isFirst = false;
          this.$store.commit("updateData", res.grades[0]);
          this.$store.commit("updateId", res.grades[0].id);
          this.$store.commit("updateGivePath", res.grades[0].give_path);
        }
        this.grade_data = res.grade_data || {};
        this.is_show_video = res.is_show_video;
        this.list[4].shows = res.userinfo.is_open_th == 2 ? true : false;
      });
    },
    gradeChange(e) {
      // this.gradeID = e;
      this.getList("", 0, 0, e);
      sessionStorage.setItem("gradeID", e);
      let index = this.options.findIndex((item) => {
        return item.id == e;
      });
      this.$store.commit("updateData", this.options[index]);
      this.$store.commit("updateId", e);
      this.$store.commit("updateGivePath", this.options[index].give_path);
      // this.givePath = this.options[index].give_path;
    },
    getBackImgFn(e) {
      this.grade_data = e.grade_data;
      if (e.grades) {
        this.options = e.grades;
        this.gradeID = e.grades[0].id;
      }
      if (e.grade_data.back_image) {
        this.backgroudImg = e.grade_data.back_image;
        sessionStorage.setItem("backimg", e.grade_data.back_image);
      } else {
        this.backgroudImg = require("../../assets/42.png");
      }
    },
    activeC(id) {
      this.activeIndex = id.path;
      if (this.$route.path.split("/")[2] != id.path) {
        this.$router.push({ name: id.path });
      }
    },
  },
  components: { navBar, navFooter, integrationDialog },
};
</script>
<style lang="scss" scoped>
html,
body {
  height: 100%;
}
.detailBox {
  min-height: 100vh;
  position: relative;
}
.header {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  .top {
    height: 260px;
    // background: url("../../assets/42.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 40px 35px 35px 35px;
    box-sizing: border-box;
    color: #fff;

    .p1 {
      font-size: 40px;
      margin-bottom: 10px;
    }
    .p2 {
      font-size: 26px;
      margin-top: 4px;
    }

    .topBottom {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .topBottomLeft {
        display: flex;
        align-items: center;
        margin-top: 44px;
        font-size: 18px;
        img {
          width: 24px;
          height: 24px;
        }
        .p11 {
          margin: 0 20px 0 8px;
        }
      }
    }
    .zwkc {
      font-size: 18px;
      display: flex;
      align-items: center;

      .el-icon-video-play {
        font-size: 24px;
        margin-right: 6px;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
    .bottomC {
      display: flex;
      align-items: center;
      padding: 14px 0;
      box-sizing: border-box;
      p {
        font-size: 30px;
        color: #3c4043;
        margin: 0 10px;
        span {
          padding: 8px 10px;
          box-sizing: border-box;
          cursor: pointer;
          user-select: none;
        }
      }
      .isActive {
        background: #e8f0ff;
        color: #4285f4;
        border-radius: 10px;
      }
    }
  }
}
</style>
